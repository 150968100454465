
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import './SummaryLimopsMaintainerGroup.css';
import { SUMMARY_LIMMOPS_GROUP, SUMMARY_LIMOPS_OPER, SUMMARY_LIMOPS_MAINTEN } from '../../../../utils/Constants';
import { getSummaryLimopsGoals, updateSummaryLimopsGoals } from '../../../../firebase';
import Button from '@material-ui/core/Button';
import GenericSnackbar from '../../../GenericSnackbar/GenericSnackbar';
function SummaryLimopsMaintainerGroup(props) {
  const [snackbar, setSnackbar] = React.useState('');
  const [summaryLimopsGoals, setSummaryLimopsGoals] = useState(SUMMARY_LIMOPS_OPER);
  const [lastState, setLastState] = React.useState();
  useEffect(() => {
    return getSummaryLimopsGoals({
      next: (querySnapshot) => {
        if (querySnapshot.data()) {
          setSummaryLimopsGoals(querySnapshot.data().data);
          setLastState(querySnapshot.data().lastUpdate);
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
  }, [props]);

  const onChangeInput = (e, id, col, val) => {
    const newGoals = { ...summaryLimopsGoals }; // Crear una copia del estado actual
    newGoals[id][col][val] = e.target.value;
    setSummaryLimopsGoals(newGoals);
  }
  const saveChanges = () => {
    const newGoals = { ...summaryLimopsGoals }; // Crear una copia del estado actual
    const modDate = new Date();
    updateSummaryLimopsGoals({ data: newGoals, lastUpdate:  modDate.toISOString() }).then(() => {
      setSnackbar({ msg: "Succesfully saved changes!", date:  modDate, severity: "success" });
    }, error => {
      console.error(error);
      setSnackbar({ msg: "Error saving changes. Try again later", date: new Date(), severity: "error" });
    });
  }

  return (
    <div>
      <table className='table-group'>
        <thead>
          <tr >
            <th></th>
            {SUMMARY_LIMMOPS_GROUP.map((title, index) => (
              <th className='theader-group borde-group' key={index}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr >
            <th></th>
            {SUMMARY_LIMMOPS_GROUP.map((title, index) => (
              <th className='doble-group'>
                <th className='simple-group amarillo-group '>
                </th>
                <th className='simple-group rojo-group borde-group' >
                </th>
              </th>
            ))}
          </tr>

          {
            SUMMARY_LIMOPS_MAINTEN.map((element) => (
              <tr>
                <th className='col1-group'>{element}</th>
                {SUMMARY_LIMMOPS_GROUP.map(y => (
                  <th className='doble-group'>
                    <th className='simple-group'>
                      <input type="text" className='none-group' onChange={(e) => onChangeInput(e, y, element.toLowerCase(), "min")} value={summaryLimopsGoals[y][element.toLowerCase()]["min"]} />
                    </th>
                    <th className='simple-group borde-group'>
                      <input type="text" className='none-group' onChange={(e) => onChangeInput(e, y, element.toLowerCase(), "max")} value={summaryLimopsGoals[y][element.toLowerCase()]["max"]} />

                    </th>
                  </th>
                ))
                }
              </tr>
            ))
          }
        </tbody>
      </table>

      <div className="cf-buttons">
      <div className="last-mod">
                    Last modification made: {lastState ? <Moment format="DD/MM/yyyy HH:mm" tz="UTC">{lastState}</Moment> : null}
                </div>
        <Button variant="contained" classes={{ root: 'vw-button' }} onClick={saveChanges} disableElevation>
          Apply changes to video wall
        </Button>
      </div>
      {/* Generic Snackbar */}
      {snackbar ? <GenericSnackbar key={snackbar.date} severity={snackbar.severity} message={snackbar.msg} /> : null}

    </div>

  );
};
export default SummaryLimopsMaintainerGroup;