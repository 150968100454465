export const FLEETS_LIST = ["319", "320", "321", "359", "767", "787", "77R"];
export const TAILS_LIST = [

  "CC-BAA",
  "CC-BAB",
  "CC-BAC",
  "CC-BAD",
  "CC-BAE",
  "CC-BAF",
  "CC-BAG",
  "CC-BAH",
  "CC-BAJ",
  "CC-BAK",
  "CC-BAL",
  "CC-BAM",
  "CC-BAQ",
  "CC-BAR",
  "CC-BAS",
  "CC-BAT",
  "CC-BAU",
  "CC-BAV",
  "CC-BAW",
  "CC-BAX",
  "CC-BAY",
  "CC-BAZ",
  "CC-BBA",
  "CC-BBB",
  "CC-BBC",
  "CC-BBD",
  "CC-BBE",
  "CC-BBF",
  "CC-BBG",
  "CC-BBH",
  "CC-BBI",
  "CC-BBJ",
  "CC-BCD",
  "CC-BCE",
  "CC-BCF",
  "CC-BDA",
  "CC-BDB",
  "CC-BDC",
  "CC-BEA",
  "CC-BEB",
  "CC-BEC",
  "CC-BED",
  "CC-BEE",
  "CC-BEF",
  "CC-BEG",
  "CC-BEH",
  "CC-BEI",
  "CC-BEJ",
  "CC-BEK",
  "CC-BEL",
  "CC-BEM",
  "CC-BEN",
  "CC-BEO",
  "CC-BEP",
  "CC-BEQ",
  "CC-BER",
  "CC-BFA",
  "CC-BFB",
  "CC-BFC",
  "CC-BFD",
  "CC-BFE",
  "CC-BFF",
  "CC-BFG",
  "CC-BFH",
  "CC-BFI",
  "CC-BFJ",
  "CC-BFK",
  "CC-BFL",
  "CC-BFM",
  "CC-BFN",
  "CC-BFO",
  "CC-BFP",
  "CC-BFQ",
  "CC-BFR",
  "CC-BFS",
  "CC-BFT",
  "CC-BFU",
  "CC-BFV",
  "CC-BFW",
  "CC-BFX",
  "CC-BGA",
  "CC-BGB",
  "CC-BGC",
  "CC-BGD",
  "CC-BGE",
  "CC-BGF",
  "CC-BGG",
  "CC-BGH",
  "CC-BGI",
  "CC-BGJ",
  "CC-BGK",
  "CC-BGL",
  "CC-BGM",
  "CC-BGN",
  "CC-BGO",
  "CC-BGP",
  "CC-BGQ",
  "CC-BGR",
  "CC-BGX",
  "CC-BGY",
  "CC-BGZ",
  "CC-BHA",
  "CC-BHB",
  "CC-BHC",
  "CC-BHD",
  "CC-BHE",
  "CC-BHG",
  "CC-BHH",
  "CC-BJD",
  "CC-BJE",
  "CC-BJF",
  "CC-BLA",
  "CC-BLE",
  "CC-BLG",
  "CC-BLH",
  "CC-BLI",
  "CC-BLJ",
  "CC-BLK",
  "CC-BLL",
  "CC-BLM",
  "CC-BLN",
  "CC-BLO",
  "CC-BLP",
  "CC-BMA",
  "CC-CML",
  "CC-COE",
  "CC-COK",
  "CC-COL",
  "CC-COO",
  "CC-COQ",
  "CC-COU",
  "CC-COX",
  "CC-COY",
  "CC-COZ",
  "CC-CPE",
  "CC-CPF",
  "CC-CPI",
  "CC-CPJ",
  "CC-CPL",
  "CC-CPM",
  "CC-CPO",
  "CC-CPQ",
  "CC-CQK",
  "CC-CQL",
  "CC-CQM",
  "CC-CQN",
  "CC-CQO",
  "CC-CQP",
  "CC-CWF",
  "CC-CWV",
  "CC-CWY",
  "CC-CXC",
  "CC-CXD",
  "CC-CXE",
  "CC-CXF",
  "CC-CXG",
  "CC-CXH",
  "CC-CXI",
  "CC-CXJ",
  "CC-CXK",
  "CC-CYI",
  "CC-CYJ",
  "CC-CYL",
  "EC-NBN",
  "EC-ODP",
  "HC-CPJ",
  "HC-CPR",
  "HC-CPY",
  "HC-CPZ",
  "PR-MAG",
  "PR-MAK",
  "PR-MAO",
  "PR-MBA",
  "PR-MBF",
  "PR-MBG",
  "PR-MBH",
  "PR-MBN",
  "PR-MBU",
  "PR-MBV",
  "PR-MBW",
  "PR-MHA",
  "PR-MHE",
  "PR-MHF",
  "PR-MHG",
  "PR-MHJ",
  "PR-MHK",
  "PR-MHM",
  "PR-MHP",
  "PR-MHQ",
  "PR-MHR",
  "PR-MHU",
  "PR-MHW",
  "PR-MHX",
  "PR-MHZ",
  "PR-MYA",
  "PR-MYC",
  "PR-MYH",
  "PR-MYI",
  "PR-MYJ",
  "PR-MYK",
  "PR-MYL",
  "PR-MYM",
  "PR-MYN",
  "PR-MYO",
  "PR-MYP",
  "PR-MYQ",
  "PR-MYR",
  "PR-MYT",
  "PR-MYV",
  "PR-MYW",
  "PR-MYX",
  "PR-MYY",
  "PR-MYZ",
  "PR-TYA",
  "PR-TYD",
  "PR-TYF",
  "PR-TYH",
  "PR-TYI",
  "PR-TYK",
  "PR-TYL",
  "PR-TYM",
  "PR-TYN",
  "PR-TYO",
  "PR-TYP",
  "PR-TYQ",
  "PR-TYR",
  "PR-TYU",
  "PR-TYV",
  "PR-XBD",
  "PR-XBE",
  "PR-XBF",
  "PR-XBH",
  "PR-XBM",
  "PR-XBN",
  "PR-XBO",
  "PR-XBS",
  "PS-LBA",
  "PS-LBB",
  "PS-LBC",
  "PS-LBD",
  "PS-LBF",
  "PS-LBJ",
  "PS-LBK",
  "PS-LBL",
  "PS-LHA",
  "PT-MOF",
  "PT-MSS",
  "PT-MSV",
  "PT-MSW",
  "PT-MSX",
  "PT-MSY",
  "PT-MSZ",
  "PT-MUB",
  "PT-MUC",
  "PT-MUD",
  "PT-MUE",
  "PT-MUF",
  "PT-MUG",
  "PT-MUH",
  "PT-MUI",
  "PT-MUJ",
  "PT-MXA",
  "PT-MXB",
  "PT-MXC",
  "PT-MXD",
  "PT-MXE",
  "PT-MXF",
  "PT-MXG",
  "PT-MXH",
  "PT-MXI",
  "PT-MXJ",
  "PT-MXL",
  "PT-MXM",
  "PT-MXN",
  "PT-MXO",
  "PT-MXP",
  "PT-MXQ",
  "PT-MZI",
  "PT-MZL",
  "PT-MZT",
  "PT-MZU",
  "PT-MZW",
  "PT-MZY",
  "PT-MZZ",
  "PT-TMA",
  "PT-TMB",
  "PT-TMC",
  "PT-TMD",
  "PT-TME",
  "PT-TMG",
  "PT-TMI",
  "PT-TML",
  "PT-TMO",
  "PT-XPA",
  "PT-XPB",
  "PT-XPC",
  "PT-XPD",
  "PT-XPE",
  "PT-XPF",
  "PT-XPG",
  "PT-XPH",
  "PT-XPI",
  "PT-XPJ",
  "PT-XPM",
  "PT-XPN",
  "PT-XPO",
  "PT-XPQ",
  "CC-BHI",
  "PR-XBU",
  "PS-LHC",
  "PS-LHD",
  "PR-XBT",
  "PS-LHG",
  "PS-LHB",
  "PS-LHE",
  "PS-LHF",
  "PS-LHI",
  "PS-LBO",
  "PR-XBV",
  "PR-XBX",
  "PR-XBZ",
  "CC-BHO",
  "CC-BHP",
  "CC-BHQ",
  "CC-BHR",
  "CC-BHJ",
  "CC-BHL",
  "CC-BHK",
  "EC-NUI",
  "EC-NCK"
];
export const FILIAL_GROUPS = [
  "LA DOM",
  "LP DOM",
  "4C DOM",
  "JJ DOM",
  "PZ DOM",
  "XL DOM",
  "LU DOM",
  "LA INT",
  "LP INT",
  "4C INT",
  "JJ INT",
  "PZ INT",
  "XL INT",
  "LU INT",
];
export const ALERT_LIST = [
  { id: 1, name: "ATB" },
  { id: 2, name: "ALT" },
  { id: 3, name: "GTB" },
  { id: 4, name: "ETD" },
  { id: 5, name: "CLD" },
];
export const STATUS_LIST = [
  { id: 1, name: "On Flight" },
  { id: 2, name: "Taxi" },
  { id: 3, name: "Delay" },
  {
    id: 4,
    name: "Arrived",
  },
];
export const FLIGHT_ALERT_LIST = [
  "ACFT TRACK",
  "CREW DUTY",
  "MCT CREW",
  "MCT PAX",
  "TOP",
  "SLOTS",
  "NOTAM",
  "VIP",
];
export const RANGE_LIST = ["1 hrs", "2 hrs", "3 hrs", "4 hrs", "5 hrs"];
export const ALL_OPTION = "ALL";
export const AIRCRAFT_STATUS = ["AOG", "CONT", "RISK"];
export const AIRCRAFT_STATUS_SORT = [
  { id: 1, name: "AOG" },
  { id: 2, name: "CONT" },
  { id: 3, name: "RISK" },
];
export const SUMMARY_LIMMOPS_SORT = [
  { id: 1, name: "OPERATOR" },
  { id: 2, name: "A/C" },
];
export const SUMMARY_LIMMOPS_STATUS = ["OPERATOR", "A/C"];
export const MAINTENANCE_AIRCRAFT_STATUS = [
  { id: 1, name: "ETR" },
  { id: 2, name: "NAD" },
  { id: 3, name: "NI" },
  { id: 4, name: "EVA" },
];
export const ZONE_CLOCKS = [
  "UTC",
  "BSB",
  "SCL",
  "MIA",
  "MAD",
  "AKL",
  "SYD",
  "LIM",
  "UIO",
  "BOG",
  "UTC",
];
export const WEATHER_CODES = [
  { key: "rain", code: "BL" },
  { key: "rain", code: "SH" },
  { key: "rain", code: "RA" },
  { key: "rain", code: "DZ" },
  { key: "rain", code: "UP" },
  { key: "rain", code: "PY" },
  { key: "rain", code: "SQ" },
  { key: "shallow", code: "MI" },
  { key: "shallow", code: "PR" },
  { key: "shallow", code: "BC" },
  { key: "freeze", code: "DR" },
  { key: "freeze", code: "FZ" },
  { key: "freeze", code: "SN" },
  { key: "freeze", code: "SG" },
  { key: "freeze", code: "IC" },
  { key: "freeze", code: "PL" },
  { key: "freeze", code: "GR" },
  { key: "freeze", code: "GS" },
  { key: "thund", code: "TS" },
  { key: "fog", code: "FG" },
  { key: "fog", code: "BR" },
  { key: "dust", code: "HZ" },
  { key: "dust", code: "VA" },
  { key: "dust", code: "DU" },
  { key: "dust", code: "FU" },
  { key: "dust", code: "SA" },
  { key: "dust", code: "PO" },
  { key: "dust", code: "DS" },
  { key: "dust", code: "SS" },
  { key: "funnel", code: "FC" },
];
export const OTP_GROUPS = [
  "DOM BR",
  "DOM BSB",
  "DOM CGH",
  "DOM CL",
  "DOM CO",
  "DOM GRU",
  "DOM PE",
  "DOM EC",
  "INTER LATAM",
  "INTER BR",
  "INTER SSC",
  "DOM SDU",
];
export const OTP_BR_GROUPS = [
  "DOM BR",
  "DOM BSB",
  "DOM CGH",
  "DOM GRU",
  "INTER BR",
  "DOM SDU",
];
export const OTP_SSC_GROUPS = [
  "DOM CL",
  "DOM CO",
  "DOM PE",
  "DOM EC",
  "INTER SSC",
  "INTER",
];
export const BR_AIRPORTS = [
  "AJU",
  "BEL",
  "BPS",
  "BSB",
  "BVB",
  "CAC",
  "CFB",
  "CGB",
  "CGH",
  "CGR",
  "CLV",
  "CNF",
  "CWB",
  "CXJ",
  "FLN",
  "FOR",
  "GIG",
  "GRU",
  "GYN",
  "IGU",
  "IMP",
  "IOS",
  "IZA",
  "JDO",
  "JJD",
  "JJG",
  "JOI",
  "JPA",
  "JTC",
  "LDB",
  "MAB",
  "MAO",
  "MCP",
  "MCZ",
  "MGF",
  "NAT",
  "NVT",
  "OPS",
  "PFB",
  "PNZ",
  "PLL",
  "PLU",
  "PMW",
  "POA",
  "PPB",
  "PVH",
  "QSC",
  "QNS",
  "RAO",
  "RBR",
  "REC",
  "SDU",
  "SJP",
  "SLZ",
  "SSA",
  "STM",
  "THE",
  "UDI",
  "UNA",
  "VCP",
  "VDC",
  "XAP",
  "VIX",
  "IBE",
];
export const SSC_AIRPORTS = [
  "ACA",
  "ADZ",
  "AEP",
  "AGT",
  "AKL",
  "AMS",
  "ANC",
  "ANF",
  "AQP",
  "ARI",
  "ASU",
  "AUA",
  "AUH",
  "AXM",
  "AYP",
  "BAQ",
  "BBA",
  "BCN",
  "BGA",
  "BHI",
  "BNA",
  "BOG",
  "BOS",
  "BQN",
  "BRC",
  "BRU",
  "CCP",
  "CDG",
  "CIX",
  "CJA",
  "CJC",
  "CLO",
  "COR",
  "CPH",
  "CPO",
  "CRD",
  "CTG",
  "CUC",
  "CUE",
  "CUN",
  "CUZ",
  "CZM",
  "DFW",
  "EYP",
  "EZE",
  "FCO",
  "FRA",
  "FTE",
  "GDL",
  "GPS",
  "GUA",
  "GYE",
  "HAM",
  "HAV",
  "HSV",
  "IGR",
  "ILQ",
  "IPC",
  "IQQ",
  "IQT",
  "IRJ",
  "JAU",
  "JFK",
  "JNB",
  "JUL",
  "LAS",
  "LAX",
  "LCK",
  "LDE",
  "LET",
  "LHR",
  "LIM",
  "LIR",
  "LIS",
  "LPA",
  "LPB",
  "LPS",
  "LSC",
  "LTX",
  "LUX",
  "MAD",
  "MCO",
  "MDE",
  "MDZ",
  "MEL",
  "MEX",
  "MHC",
  "MIA",
  "MID",
  "MPN",
  "MTR",
  "MVD",
  "MXP",
  "NAS",
  "NQN",
  "NVA",
  "ONT",
  "ORD",
  "PAE",
  "PBI",
  "PCL",
  "PDL",
  "PDP",
  "PEI",
  "PEM",
  "PIO",
  "PIU",
  "PLS",
  "PMC",
  "PNT",
  "POS",
  "PPT",
  "PSO",
  "PSS",
  "PTY",
  "PUJ",
  "PUQ",
  "PVR",
  "QRO",
  "RGL",
  "ROS",
  "RSW",
  "SBS",
  "SCL",
  "SCY",
  "SDF",
  "SDQ",
  "SHV",
  "SID",
  "SJO",
  "SKF",
  "SLA",
  "SMR",
  "SNN",
  "SYD",
  "TBP",
  "TCQ",
  "TLC",
  "TLS",
  "TLV",
  "TPE",
  "TPP",
  "TRU",
  "TUC",
  "TYL",
  "UIO",
  "USH",
  "VCV",
  "VLC",
  "VRA",
  "VUP",
  "VVI",
  "XFW",
  "YHM",
  "ZAL",
  "ZCO",
  "ZOS",
  "BFM", 
  "CCS", 
  "ILO", 
  "JAE", 
  "JUJ", 
  "MBJ", 
  "MEC", 
  "OCC", 
  "RCH",
];

export const USER_ROLES = [
  "USER",
  "USER ADMIN",
  "OTP",
  "SUPER USER",
  "SUPER USER LIMOPS",
  "SUPER USER CREWSTANDBY",
];

export const PARAM_GOAL = {
  "DOM SDU": "DOMÉSTICO BRASIL",
  "DOM BR": "DOMÉSTICO BRASIL",
  "DOM BSB": "DOMÉSTICO BRASIL",
  "DOM CGH": "DOMÉSTICO BRASIL",
  "DOM GRU": "DOMÉSTICO BRASIL",
  INTER: "INTERNACIONAL",
  "INTER BR": "INTERNACIONAL",
  "INTER SSC": "INTERNACIONAL",
  "ATAM TOTAL": "LATAM",
  "DOM CL": "DOMÉSTICO CHILE",
  "DOM CO": "DOMÉSTICO COLOMBIA",
  "DOM EC": "DOMÉSTICO ECUADOR",
  "DOM PE": "DOMÉSTICO PERÚ",
};

export const SUMMARY_LIMMOPS_GROUP = [
  "WB LA",
  "WB LP",
  "WB BR",
  "NB CL",
  "NB CO",
  "NB LP",
  "NB BR",
  "NB XL",
];
export const SUMMARY_LIMMOPS_SUBFLOTA = [
  "319CL",
  "320CL",
  "321CL",
  "319CO",
  "320CO",
  "321CO",
  "319PE",
  "320PE",
  "321PE",
  "319EC",
  "320EC",
  "321EC",
  "319BR",
  "320BR",
  "321BR",
  "763LA",
  "789LA",
  "77RLA",
  "763PE",
  "788PE",
  "789PE",
  "77RPE",
  "789BR",
  "77RBR",
  "763BR",
  "788BR",
  "767LA",
  "787LA",
  "777LA",
  "767PE",
  "787PE",
  "777PE",
  "777BR",
  "767BR",
  "787BR",
  "788LA",
];
export const SUMMARY_LIMMOPS_OWNER = ["LA", "4C", "LP", "XL", "JJ"];

export const WEATHER_GROUPS_INTER = [
  "FRA",
  "MEL",
  "SYD",
  "HAV",
  "MPN",
  "CDG",
  "TLV",
  "LUX",
  "ACA",
  "CUN",
  "CZM",
  "AMS",
  "AUA",
  "AKL",
  "PTY",
  "PPT",
  "PUJ",
  "SDQ",
  "JNB",
  "AUH",
  "PDP",
  "ANC",
  "LAS",
  "LAX",
  "BCN",
  "MAD",
  "LHR",
  "FCO",
  "MXP",
  "MEX",
  "ASU",
  "LIS",
  "MVD",
  "JFK",
  "MCO",
  "MIA",
  "FRA",
  "MEL",
  "SYD",
  "HAV",
  "MPN",
  "CDG",
  "TLV",
  "LUX",
  "ACA",
  "CUN",
  "CZM",
  "AMS",
  "AUA",
  "AKL",
  "PTY",
  "PPT",
  "PUJ",
  "SDQ",
  "JNB",
  "AUH",
  "PDP",
  "ANC",
  "LAS",
  "LAX",
  "BCN",
  "MAD",
  "LHR",
  "FCO",
  "MXP",
  "MEX",
  "ASU",
  "LIS",
  "MVD",
  "JFK",
  "MCO",
  "MIAFRA",
  "MEL",
  "SYD",
  "HAV",
  "MPN",
  "CDG",
  "TLV",
  "LUX",
  "ACA",
  "CUN",
  "CZM",
  "AMS",
  "AUA",
  "AKL",
  "PTY",
  "PPT",
  "PUJ",
  "SDQ",
  "JNB",
  "AUH",
  "PDP",
  "ANC",
  "LAS",
  "LAX",
  "BCN",
  "MAD",
  "LHR",
  "FCO",
  "MXP",
  "MEX",
  "ASU",
  "LIS",
  "MVD",
  "JFK",
  "MCO",
  "MIA",
  "FRA",
  "MEL",
  "SYD",
  "HAV",
  "MPN",
  "CDG",
  "TLV",
  "LUX",
  "ACA",
  "CUN",
  "CZM",
  "AMS",
  "AUA",
  "AKL",
  "PTY",
  "PPT",
  "PUJ",
  "SDQ",
  "JNB",
  "AUH",
  "PDP",
  "ANC",
  "LAS",
  "LAX",
  "BCN",
  "MAD",
  "LHR",
  "FCO",
  "MXP",
  "MEX",
  "ASU",
  "LIS",
  "MVD",
  "JFK",
  "MCO",
  "MIA",
  "COR",
  "EZE",
  "LPB",
  "VVI",
  "FLN",
  "GIG",
  "GRU",
  "IPC",
  "VRA",
  "GYE",
  "UIO",
  "POA",
  "SCL",
  "BOG",
  "LIM",
];
export const WEATHER_GROUPS_INTBR = [
  "TLS",
  "BOS",
  "FRA",
  "BCN",
  "MAD",
  "LHR",
  "FCO",
  "MXP",
  "ASU",
  "LIS",
  "JFK",
  "MCO",
  "MIA",
  "IATA",
  "AEP",
  "MDZ",
  "MEX",
  "MVD",
  "EZE",
  "SCL",
  "BOG",
  "LIM",
];

export const WEATHER_GROUPS_4C = [
  "ADZ",
  "BAQ",
  "BGA",
  "CLO",
  "CTG",
  "CUC",
  "EYP",
  "LET",
  "MDE",
  "MTR",
  "NVA",
  "PEI",
  "PSO",
  "SMR",
  "VUP",
  "BOG",
];
export const WEATHER_GROUPS_4M = [
  "AEP",
  "BHI",
  "BRC",
  "COR",
  "CRD",
  "EZE",
  "FTE",
  "IGR",
  "IRJ",
  "MDZ",
  "NQN",
  "PSS",
  "RGL",
  "ROS",
  "SLA",
  "TUC",
  "USH",
];

export const WEATHER_GROUPS_ALT = [
  "HAM",
  "XFW",
  "NAS",
  "BRU",
  "PLS",
  "CXH",
  "YHM",
  "SID",
  "LIR",
  "SJO",
  "VRA",
  "CPH",
  "LPA",
  "VLC",
  "LDE",
  "GUA",
  "SNN",
  "GDL",
  "MID",
  "PVR",
  "QRO",
  "TLC",
  "PDL",
  "TPE",
  "POS",
  "BNA",
  "BQN",
  "DFW",
  "HSV",
  "LCK",
  "LPS",
  "ONT",
  "ORD",
  "PAE",
  "PBI",
  "RSW",
  "SBS",
  "SDF",
  "SHV",
  "SKF",
  "VCV",
  "AGT",
];
export const WEATHER_GROUPS_CL = [
  "ANF",
  "ARI",
  "BBA",
  "CCP",
  "CJC",
  "CPO",
  "IPC",
  "IQQ",
  "LSC",
  "MHC",
  "PMC",
  "PNT",
  "PUQ",
  "ZAL",
  "ZCO",
  "ZOS",
  "SCL",
];
export const WEATHER_GROUPS_JJ = [
  "GYN",
  "AJU",
  "BEL",
  "BPS",
  "BSB",
  "BVB",
  "CFB",
  "CGB",
  "CGH",
  "CGR",
  "CLV",
  "CNF",
  "CWB",
  "FLN",
  "FOR",
  "GIG",
  "GRU",
  "IGU",
  "IMP",
  "IOS",
  "JJD",
  "JJG",
  "JOI",
  "JPA",
  "JTC",
  "OPS",
  "CAC",
  "CXJ",
  "IZA",
  "JDO",
  "LDB",
  "MAB",
  "MAO",
  "MCP",
  "MCZ",
  "MGF",
  "MOC",
  "NAT",
  "NVT",
  "PMW",
  "PNZ",
  "POA",
  "PPB",
  "PVH",
  "RAO",
  "RBR",
  "REC",
  "SDU",
  "SJP",
  "SLZ",
  "SSA",
  "STM",
  "THE",
  "UDI",
  "VDC",
  "VIX",
  "XAP",
  "IBE",
];
export const WEATHER_GROUPS_SSC = [
  "LPB",
  "VVI",
  "AGT",
  "AQP",
  "AYP",
  "CIX",
  "CJA",
  "CUZ",
  "ILQ",
  "IQT",
  "JAU",
  "JUL",
  "PCL",
  "PEM",
  "PIO",
  "PIU",
  "TBP",
  "TCQ",
  "TPP",
  "TRU",
  "TYL",
  "LIM",
  "AEP",
  "BHI",
  "BRC",
  "CRD",
  "FTE",
  "IGR",
  "IRJ",
  "MDZ",
  "NQN",
  "PSS",
  "RGL",
  "ROS",
  "SLA",
  "TUC",
  "USH",
  "ANF",
  "ARI",
  "BBA",
  "CCP",
  "CJC",
  "CPO",
  "IQQ",
  "LSC",
  "MHC",
  "PMC",
  "PNT",
  "PUQ",
  "ZAL",
  "ZCO",
  "ZOS",
  "ADZ",
  "BAQ",
  "BGA",
  "CLO",
  "CTG",
  "CUC",
  "EYP",
  "LET",
  "MDE",
  "MTR",
  "NVA",
  "PEI",
  "PSO",
  "SMR",
  "VUP",
  "LIR",
  "SJO",
  "HAV",
  "CUE",
  "LTX",
  "SCY",
  "GPS",
  "ACA",
  "CUN",
  "CZM",
  "PTY",
  "PUJ",
  "SDQ",
  "PDP",
  "MEX",
  "MVD",
  "EZE",
  "IPC",
  "VRA",
  "GYE",
  "UIO",
  "SCL",
  "BOG",
  "BFM", 
  "CCS", 
  "ILO", 
  "JAE", 
  "JUJ", 
  "MBJ", 
  "MEC", 
  "OCC", 
  "RCH",
];

export const WEATHER_GROUPS_XL = ["CUE", "GYE", "LTX", "SCY", "UIO", "GPS"];
export const WEATHER_GROUPS_LP = [
  "AQP",
  "AYP",
  "CIX",
  "CJA",
  "CUZ",
  "ILQ",
  "IQT",
  "JAU",
  "JUL",
  "PCL",
  "PEM",
  "PIO",
  "PIU",
  "TBP",
  "TCQ",
  "TPP",
  "TRU",
  "TYL",
  "LIM",
];
export const SUMMARY_LIMOPS_MAINTEN = [
  "ETP",
  "APU",
  "RNV",
  "WXS",
  "BRK",
  "PAX",
  "CGO",
];

export const SUMMARY_LIMOPS_OPER = {
  "WB LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "WB LP": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "WB BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "NB CL": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "NB CO": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "NB LP": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "NB BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "NB XL": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "319CL": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "320CL": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "321CL": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "319CO": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "320CO": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "321CO": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "319PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "320PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "321PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "319EC": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "320EC": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "321EC": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "319BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "320BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "321BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "763LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "789LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "77RLA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "763PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "788PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "789PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "77RPE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "789BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "77RBR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "763BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "788BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "767LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "787LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "777LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "767PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "787PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "777PE": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "777BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "767BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "787BR": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
  "788LA": {
    apu: { min: 100, max: 100 },
    rnv: { min: 100, max: 100 },
    wxs: { min: 100, max: 100 },
    brk: { min: 100, max: 100 },
    pax: { min: 100, max: 100 },
    cgo: { min: 100, max: 100 },
    etp: { min: 100, max: 100 },
  },
};
export const SUMMARY_LIMMOPS_SUBFLOTA1 = [
  "319CL",
  "320CL",
  "321CL",
  "319CO",
  "320CO",
  "321CO",
  "319PE",
  "320PE",
  "321PE",
  "319EC",
  "789PE",
  "77RPE",
  "789BR",
  "77RBR",
  "763BR",
  "787PE",
  "777PE",
  "777BR",
];
export const SUMMARY_LIMMOPS_SUBFLOTA2 = [
  "320EC",
  "321EC",
  "319BR",
  "320BR",
  "321BR",
  "763LA",
  "789LA",
  "77RLA",
  "763PE",
  "788PE",
  "788BR",
  "767LA",
  "787LA",
  "777LA",
  "767PE",
  "767BR",
  "787BR",
  "788LA",
];

export const CREW_STANDBY_SSC = [
  "320CL",
  "320PE",
  "320CO",
  "320EC",
  "320PZ",
  "787CL",
  "767PE",
  "787PE",
  "777BR",
  "787BR"
];

export const SUMMARY_CREW_STANDBY_SSC_MAINTEN = [
  "320CL",
  "320PE",
  "320CO",
  "320EC",
  "320PZ",
  "787CL",
  "767PE",
  "787PE",
  "777BR",
  "787BR"
];

export const SUMMARY_CREW_STANDBY_SSC_OPER = {
  "Mando": {
    "320CL": {
      "0_0": {min:1,max:2},
      "0_2_0": {min:1,max:2},
      "0_3_0": {min:1,max:2},
      "0_4_0": {min:1,max:2},
      "0_5_0": {min:1,max:2},
      "0_6_0": {min:1,max:2},
      "0_7_0": {min:1,max:2},
      "0_7b_0":{min:1,max:2}
    },
    "320PE": {
      "1_0": {min:1,max:2},
      "1_2_0": {min:1,max:2},
      "1_3_0": {min:1,max:2},
      "1_4_0": {min:1,max:2},
      "1_5_0": {min:1,max:2},
      "1_6_0": {min:1,max:2},
      "1_7_0": {min:1,max:2},
      "1_7b_0":{min:1,max:2}
    },
    "320CO": {
      "2_0": {min:1,max:2},
      "2_2_0": {min:1,max:2},
      "2_3_0": {min:1,max:2},
      "2_4_0": {min:1,max:2},
      "2_5_0": {min:1,max:2},
      "2_6_0": {min:1,max:2},
      "2_7_0": {min:1,max:2},
      "2_7b_0":{min:1,max:2}
    },
    "320EC": {
      "3_0": {min:1,max:2},
      "3_2_0": {min:1,max:2},
      "3_3_0": {min:1,max:2},
      "3_4_0": {min:1,max:2},
      "3_5_0": {min:1,max:2},
      "3_6_0": {min:1,max:2},
      "3_7_0": {min:1,max:2},
      "3_7b_0":{min:1,max:2}
    },
    "787CL": {
      "4_0": {min:1,max:2},
      "4_2_0": {min:1,max:2},
      "4_3_0": {min:1,max:2},
      "4_4_0": {min:1,max:2},
      "4_5_0": {min:1,max:2},
      "4_6_0": {min:1,max:2},
      "4_7_0": {min:1,max:2},
      "4_7b_0":{min:1,max:2}
    },
    "767PE": {
      "5_0": {min:1,max:2},
      "5_2_0": {min:1,max:2},
      "5_3_0": {min:1,max:2},
      "5_4_0": {min:1,max:2},
      "5_5_0": {min:1,max:2},
      "5_6_0": {min:1,max:2},
      "5_7_0": {min:1,max:2},
      "5_7b_0":{min:1,max:2}
    },
    "787PE": {
      "6_0": {min:1,max:2},
      "6_2_0": {min:1,max:2},
      "6_3_0": {min:1,max:2},
      "6_4_0": {min:1,max:2},
      "6_5_0": {min:1,max:2},
      "6_6_0": {min:1,max:2},
      "6_7_0": {min:1,max:2},
      "6_7b_0":{min:1,max:2}
    },
    "777BR": {
      "7_0": {min:1,max:2},
      "7_2_0": {min:1,max:2},
      "7_3_0": {min:1,max:2},
      "7_4_0": {min:1,max:2},
      "7_5_0": {min:1,max:2},
      "7_6_0": {min:1,max:2},
      "7_7_0": {min:1,max:2},
      "7_7b_0":{min:1,max:2}
    },
    "787BR": {
      "8_0": {min:1,max:2},
      "8_2_0": {min:1,max:2},
      "8_3_0": {min:1,max:2},
      "8_4_0": {min:1,max:2},
      "8_5_0": {min:1,max:2},
      "8_6_0": {min:1,max:2},
      "8_7_0": {min:1,max:2},
      "8_7b_0":{min:1,max:2}
    }
  },
  "Cabina": {
    "320CL": {
      "0_1": {min:1,max:2},
      "0_2_1": {min:1,max:2},
      "0_3_1": {min:1,max:2},
      "0_4_1": {min:1,max:2},
      "0_5_1": {min:1,max:2},
      "0_6_1": {min:1,max:2},
      "0_7_1": {min:1,max:2},
      "0_7b_1":{min:1,max:2}
    },
    "320PE": {
      "1_1": {min:1,max:2},
      "1_2_1": {min:1,max:2},
      "1_3_1": {min:1,max:2},
      "1_4_1": {min:1,max:2},
      "1_5_1": {min:1,max:2},
      "1_6_1": {min:1,max:2},
      "1_7_1": {min:1,max:2},
      "1_7b_1":{min:1,max:2}
    },
    "320CO": {
      "2_1": {min:1,max:2},
      "2_2_1": {min:1,max:2},
      "2_3_1": {min:1,max:2},
      "2_4_1": {min:1,max:2},
      "2_5_1": {min:1,max:2},
      "2_6_1": {min:1,max:2},
      "2_7_1": {min:1,max:2},
      "2_7b_1":{min:1,max:2}
    },
    "320EC": {
      "3_1": {min:1,max:2},
      "3_2_1": {min:1,max:2},
      "3_3_1": {min:1,max:2},
      "3_4_1": {min:1,max:2},
      "3_5_1": {min:1,max:2},
      "3_6_1": {min:1,max:2},
      "3_7_1": {min:1,max:2},
      "3_7b_1":{min:1,max:2}
    },
    "787CL": {
      "4_1": {min:1,max:2},
      "4_2_1": {min:1,max:2},
      "4_3_1": {min:1,max:2},
      "4_4_1": {min:1,max:2},
      "4_5_1": {min:1,max:2},
      "4_6_1": {min:1,max:2},
      "4_7_1": {min:1,max:2},
      "4_7b_1":{min:1,max:2}
    },
    "767PE": {
      "5_1": {min:1,max:2},
      "5_2_1": {min:1,max:2},
      "5_3_1": {min:1,max:2},
      "5_4_1": {min:1,max:2},
      "5_5_1": {min:1,max:2},
      "5_6_1": {min:1,max:2},
      "5_7_1": {min:1,max:2},
      "5_7b_1":{min:1,max:2}
    },
    "787PE": {
      "6_1": {min:1,max:2},
      "6_2_1": {min:1,max:2},
      "6_3_1": {min:1,max:2},
      "6_4_1": {min:1,max:2},
      "6_5_1": {min:1,max:2},
      "6_6_1": {min:1,max:2},
      "6_7_1": {min:1,max:2},
      "6_7b_1":{min:1,max:2}
    },
    "777BR": {
      "7_1": {min:1,max:2},
      "7_2_1": {min:1,max:2},
      "7_3_1": {min:1,max:2},
      "7_4_1": {min:1,max:2},
      "7_5_1": {min:1,max:2},
      "7_6_1": {min:1,max:2},
      "7_7_1": {min:1,max:2},
      "7_7b_1":{min:1,max:2}
    },
    "787BR": {
      "8_1": {min:1,max:2},
      "8_2_1": {min:1,max:2},
      "8_3_1": {min:1,max:2},
      "8_4_1": {min:1,max:2},
      "8_5_1": {min:1,max:2},
      "8_6_1": {min:1,max:2},
      "8_7_1": {min:1,max:2},
      "8_7b_1":{min:1,max:2}
    }
  }
};

export const CREW_STANDBY_SSC_MAINTEN = 
{
  "320CL": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "320PE": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "320CO": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "320EC": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "320PZ": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "787CL": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "767PE": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "787PE": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "777BR": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  },
  "787BR": {
    "AM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "PM": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    },
    "ATO": {
      "Mando": {"min": [0, 1, 1, 1], "max": [1, 1, 1, 1]},
      "Cabina": {"min": [1, 0, 1, 1], "max": [1, 0, 1, 1]}
    },
    "T24": {
      "Mando": {"min": 1, "max": 2},
      "Cabina": {"min": 1, "max": 2}
    }
  }
}

export const SUMMARY_CREW_STANDBY_SSC = [
  "Mando",
  "Cabina"
];

export const CREW_STANDBY_BR_AM_GROUP = [
  "00:00 - 03:00",
  "03:00 - 06:00",
  "06:00 - 09:00",
  "09:00 - 12:00"
];
export const CREW_STANDBY_BR_PM_GROUP = [
  "12:00 - 15:00",
  "15:00 - 18:00",
  "18:00 - 21:00",
  "21:00 - 00:00"
];

export const CREW_STANDBY_BR_MANDO_CABINA = [
  "Mando",
  "Cabina",
  "Mando",
  "Cabina",
  "Mando",
  "Cabina",
  "Mando",
  "Cabina"
];

export const CREW_STANDBY_BR = [
  "320SAO",
  "320RIO",
  "320BSB",
  "320POA",
  "787SAO",
  "777SAO"
];

export const CREW_STANDBY_BRA_MAINTEN_AM = {
  "320SAO": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320RIO": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320BSB": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320POA": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "787SAO": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "777SAO": {
    "00:00 - 03:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "03:00 - 06:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "06:00 - 09:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "09:00 - 12:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  }
};

export const CREW_STANDBY_BRA_MAINTEN_PM = {
  "320SAO": {
    "12:00 - 15:00": {
    "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
   "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
    "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320RIO": {
    "12:00 - 15:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320BSB": {
    "12:00 - 15:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
    "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "320POA": {
    "12:00 - 15:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "787SAO": {
    "12:00 - 15:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
      "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  },

  "777SAO": {
    "12:00 - 15:00": {
    "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "15:00 - 18:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "18:00 - 21:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    },
    "21:00 - 00:00": {
     "Mando": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      },
      "Cabina": {
        "Min": { "Ato": 1, "Casa": 1 },
        "Max": { "Ato": 0, "Casa": 0 }
      }
    }
  }
};