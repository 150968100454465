import { SUMMARY_LIMMOPS_GROUP, SUMMARY_LIMMOPS_SUBFLOTA, SUMMARY_LIMMOPS_OWNER } from '../utils/Constants';

class SummaryLimopsService {

    summaryLimopsCounters(summaryLimopsList, config) {

        let carrCdg = [];
        let carrCdgCount = [];
        let hashMatricula = [];
        let summaryLimopsFiltrer = [];
        let filterLimops = this.sumaryLimmopsFilter(config);
        let operator = config?.summaryLimopsConfig?.filter ? config?.summaryLimopsConfig?.filter : "operatorType";

        summaryLimopsList.forEach(SummaryLimopsitem => {
            if (!hashMatricula.includes(SummaryLimopsitem['carrCdgBase'] + SummaryLimopsitem['matricula'])
                && filterLimops.includes(SummaryLimopsitem[operator])) {
                hashMatricula.push(SummaryLimopsitem['carrCdgBase'] + SummaryLimopsitem['matricula']);
                summaryLimopsFiltrer.push(SummaryLimopsitem);
            }
        });
        summaryLimopsList.forEach(SummaryLimopsitem => {
            if (!carrCdg.includes(SummaryLimopsitem['carrCdgBase'])
                && filterLimops.includes(SummaryLimopsitem[operator])
                && SUMMARY_LIMMOPS_OWNER.includes(SummaryLimopsitem['carrCdgBase'])) {
                carrCdg.push(SummaryLimopsitem['carrCdgBase']);
                carrCdgCount.push({ carrier: SummaryLimopsitem['carrCdgBase'], count: 0 })
            }
        });
        carrCdgCount.forEach(carrCdgCountItem => {
            summaryLimopsFiltrer.forEach(SummaryLimopsitem => {
                if (carrCdgCountItem.carrier === SummaryLimopsitem['carrCdgBase'] && SummaryLimopsitem['materialType'] !== null) {
                    carrCdgCountItem.count++;
                }
            });
        });
        return carrCdgCount;
    }

    sumaryLimmopsFilter(config) {

        let filter = config?.summaryLimopsConfig?.filter ? config?.summaryLimopsConfig?.filter : "operatorType";
        let operatorGroupFilter = config?.summaryLimopsConfig?.operatorGroupFilter.length ? config?.summaryLimopsConfig?.operatorGroupFilter : SUMMARY_LIMMOPS_GROUP;
        let operatorSubFlotaFilter = config?.summaryLimopsConfig?.operatorSubFlotaFilter.length ? config?.summaryLimopsConfig?.operatorSubFlotaFilter : SUMMARY_LIMMOPS_SUBFLOTA;
        return filter === "operatorType" ? operatorGroupFilter : operatorSubFlotaFilter;

    }

    operatorCounters(summaryLimopsList, config) {
        let operLimitByAircraft = [];
        let operLimitByBusiness = [];
        // ORDENA DATA BY AIRCRAFT REPARAR ESTA FUNCION
        operLimitByAircraft = this.orderSummaryLimopsListbyAircraft(summaryLimopsList);
        //AGRUPA AIRCRAFT BY BUSINESS
        operLimitByBusiness = this.groupOperLimitByAircraft(config, operLimitByAircraft);
        return operLimitByBusiness;
    }

    orderSummaryLimopsListbyAircraft(summaryLimopsList) {
        let operLimitByAircraft = [];
        let hashList = [];
        summaryLimopsList.forEach(summaryLimopsitem => {
            if (!hashList.includes(summaryLimopsitem['matricula'] + summaryLimopsitem['carrCdgBase'])) {
                hashList.push(summaryLimopsitem['matricula'] + summaryLimopsitem['carrCdgBase']);
                operLimitByAircraft.push({
                    matricula: summaryLimopsitem['matricula'], base: summaryLimopsitem['carrCdgBase'],
                    operatorType: summaryLimopsitem['operatorType'], subflotaOwner: summaryLimopsitem['subflotaOwner'],
                    ac: 0, etp: 0, apu: 0, brk: 0, wxs: 0, rnv: 0, pax: 0, cgo: 0,
                });
            }
        });

        operLimitByAircraft.forEach(hashCarr => {
            summaryLimopsList.forEach(summaryLimopsitem => {
                if (hashCarr.matricula === summaryLimopsitem['matricula'] && hashCarr.base === summaryLimopsitem['carrCdgBase']) {
                    switch (summaryLimopsitem['materialType']) {
                        case "RNV":
                            hashCarr.rnv = 1;
                            break;
                        case "ETP":
                            this.validaEtp(hashCarr);
                            break;
                        case "APU":
                            hashCarr.apu = 1;
                            break;
                        case "WXS":
                            hashCarr.wxs = 1;
                            break;
                        case "BRK":
                            hashCarr.brk = 1;
                            break;
                        case "PAX":
                            hashCarr.pax = 1;
                            break;
                        case "CGO":
                            hashCarr.cgo = 1;
                            break;
                        default:
                            break;
                    }

                    hashCarr.ac = this.getAc(hashCarr);
                }
            });
        });
        return operLimitByAircraft;

    }
    validaEtp(hashCarr) {
        hashCarr.etp = 1;
    }

    getAc(hashCarr) {
        let ac = 0;
        ac = (hashCarr.etp + hashCarr.apu + hashCarr.brk + hashCarr.wxs + hashCarr.rnv + hashCarr.pax + hashCarr.cgo) > 0 ? 1 : 0;
        return ac;
    }

    groupOperLimitByAircraft(config, operLimitByAircraft) {
        let filterLimops = this.sumaryLimmopsFilter(config);
        let operator = config?.summaryLimopsConfig?.filter ? config?.summaryLimopsConfig?.filter : 'operatorType';
        let operLimitByBusiness = [];
        let summaryLimmopsBusinessFilter = [];
        filterLimops.forEach(sumaryLimmopsBusiness => {
            operLimitByAircraft.forEach(operLimitAircraft => {
                if (sumaryLimmopsBusiness === operLimitAircraft[operator]
                    && !summaryLimmopsBusinessFilter.includes(sumaryLimmopsBusiness)) {
                    summaryLimmopsBusinessFilter.push(sumaryLimmopsBusiness);
                }
            });
        });
        summaryLimmopsBusinessFilter.forEach(summaryLimopsBusinessItem => {
            operLimitByBusiness.push({
                ac: 0,
                etp: 0,
                apu: 0,
                brk: 0,
                wxs: 0,
                rnv: 0,
                pax: 0,
                cgo: 0,
                operatorType: summaryLimopsBusinessItem
            });
        })
        operLimitByBusiness.forEach(sumaryLimmopsBusiness => {
            operLimitByAircraft.forEach(operLimitAircraft => {
                //los estoy summando todos y solo se debieran sumar 6 segun tabla de prihoridad
                if (sumaryLimmopsBusiness['operatorType'] === operLimitAircraft[operator]) {
                    this.rulerMaxoperatorType(sumaryLimmopsBusiness, operLimitAircraft);
                }
            });
        });
        return operLimitByBusiness;
    }

    rulerMaxoperatorType(sumaryLimmopsBusiness, operLimitAircraft) {

        sumaryLimmopsBusiness.ac += operLimitAircraft.ac;
        sumaryLimmopsBusiness.etp += operLimitAircraft.etp;
        sumaryLimmopsBusiness.apu += operLimitAircraft.apu;
        sumaryLimmopsBusiness.brk += operLimitAircraft.brk;
        sumaryLimmopsBusiness.wxs += operLimitAircraft.wxs;
        sumaryLimmopsBusiness.rnv += operLimitAircraft.rnv;
        sumaryLimmopsBusiness.pax += operLimitAircraft.pax;
        sumaryLimmopsBusiness.cgo += operLimitAircraft.cgo;
    }

    groupOperLimitByBusinessbyDots(operLimitByBusiness) {
        let summaryLimopsDot = [];
        let summaryLimopsGroup = [];
        operLimitByBusiness.forEach((summaryLimopsItem) => {
            if (summaryLimopsDot.length > 2) {
                summaryLimopsGroup.push(summaryLimopsDot);
                summaryLimopsDot = [];
                summaryLimopsDot.push(summaryLimopsItem);
            } else {
                summaryLimopsDot.push(summaryLimopsItem);
            }

        });
        summaryLimopsGroup.push(summaryLimopsDot);
        return summaryLimopsGroup;
    }

    summaryLimopsDots(summaryLimopsList) {
        let SummaryLimopsGroup = [];
        let summaryLimopsDot = [];
        summaryLimopsList.forEach((summaryLimopsItem) => {
            if (summaryLimopsDot.length > 3) {
                SummaryLimopsGroup.push(summaryLimopsDot);
                summaryLimopsDot = [];
                summaryLimopsDot.push(summaryLimopsItem);
            } else {
                summaryLimopsDot.push(summaryLimopsItem);
            }

        });
        SummaryLimopsGroup.push(summaryLimopsDot);
        return SummaryLimopsGroup;

    }

    countAircraftByLimitOperationAndParSft(parSubFlotaList, fleetDictionaryList, config) {
        let operator = config?.summaryLimopsConfig?.filter ? config?.summaryLimopsConfig?.filter : 'operatorType';
        let { fleetDictionary, operatorList, subFlotaList } = this.generateListFleetParSubFlota(fleetDictionaryList);

        fleetDictionary.forEach(fd => {
            parSubFlotaList.forEach(psf => {
                if ((psf['carrCdgBase'] === fd.cdgBase) && (psf['sbFlotaIfn'] === fd.sbFlotaIfn)) {
                    if (operator === "operatorType") {
                        operatorList.forEach(operatorList => {
                            if (operatorList.group === fd.operator) {
                                operatorList.count++;
                            }
                        });
                        subFlotaList = null;
                    } else {
                        subFlotaList.forEach(sf => {
                            if (sf.subtype === fd.subtype) {
                                sf.count++;
                            }
                        });
                        operatorList = null;
                    }
                }
            })
        });
        let resultObject = { subFlotaList, operatorList };
        return resultObject;
    }

    generateListFleetParSubFlota(fleetDictionaryList) {
        let fleetDictionary = [];
        let operatorList = [
            { group: 'NB CL', count: 0 },
            { group: 'NB CO', count: 0 },
            { group: 'NB LP', count: 0 },
            { group: 'NB BR', count: 0 },
            { group: 'NB XL', count: 0 },
            { group: 'WB LA', count: 0 },
            { group: 'WB LP', count: 0 },
            { group: 'WB BR', count: 0 }
        ];
        let subFlotaList = [];
        fleetDictionaryList.forEach(df => {
            fleetDictionary.push({
                cdgBase: df['aircraftOwner'],
                sbFlotaIfn: df['aircraftSubType'],
                operator: df['operatorType'],
                subtype: df['subFlotaType']
            });
            subFlotaList.push({
                subtype: df['subFlotaType'], count: 0
            });
        });
        return { fleetDictionary, operatorList, subFlotaList };
    }

}

export default SummaryLimopsService;


