import React, { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";

export const AuthContext = React.createContext();

function AuthProvider(props) {
    const [authState, setAuthState] = useState({ user: null, initLoad: true });

    useEffect(() => {
        async function validateUser(email, uid) {
            let user = null;
            try {
                const emailDoc = await firestore.collection('videowall-users').doc(email.toLowerCase()).get();

                if (emailDoc.exists) {
                    user = emailDoc;
                    return user;
                }
                const uidDoc = await firestore.collection('videowall-users').doc(uid).get();

                if (uidDoc.exists) {
                    user = uidDoc;
                    return user;
                }
                return null;
            } catch (err) {
                return null;
            }
        }

        auth.onAuthStateChanged((userAuth) => {
            setAuthState({ loading: true, initLoad: true });

            if (userAuth && !userAuth.isAnonymous) {
                validateUser(userAuth.email, userAuth.uid).then((user) => {
                    if (user && user.exists && user.data().role && user.data().cco) {
                        setAuthState({ user: userAuth, role: user.data().role, cco: user.data().cco });
                    } else {
                        setAuthState({ unauthorized: true, redirect: true });
                    }
                }).catch((error) => {
                    console.error("error al validar usuario", error);
                    setAuthState({ unauthorized: true, redirect: true });
                }).finally(() => {
                    setAuthState(prevState => ({ ...prevState, loading: false, initLoad: false }));
                });
            } else {
                setAuthState({ redirect: true });
                setAuthState(prevState => ({ ...prevState, loading: false, initLoad: false })); //añadido para corregir el loading.
            }
        });
    }, []);

    return (
        <AuthContext.Provider value={authState}>
            {props.children}
        </AuthContext.Provider>
    );

}

export default AuthProvider;
