import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  streamCrewStandby,
  getCrewStandbySsc,
  getCrewStandbyBr
} from "../../firebase";
import { currentSlideCrew3 } from "../../hooks/summaryLimopsCarrousel";
import GenericTitle from "../GenericTitle/GenericTitle";
import Loader from "../Loader/Loader";
import "./CrewStandby.css";
import CrewStandbyGroupSSC from "./CrewStandbyGroupSSC/CrewStandbyGroupSSC";
import CrewStandbyGroupBR from "./CrewStandbyGroupBR/CrewStandbyGroupBR";

let actualCfSlide = 1;
let intervalId;
let lastUpdate;

function reorderBRGroups(groups) {
  const desiredOrder = [
    "320SAO",
    "320RIO",
    "320BSB",
    "320POA",
    "777SAO",
    "787SAO",
  ];

  return groups.sort((groupA, groupB) => {
    const aKey = groupA[0].subFleetCode + groupA[0].baseCode;
    const bKey = groupB[0].subFleetCode + groupB[0].baseCode;
    const idxA = desiredOrder.indexOf(aKey);
    const idxB = desiredOrder.indexOf(bKey);
    const finalA = idxA === -1 ? 999 : idxA;
    const finalB = idxB === -1 ? 999 : idxB;
    return finalA - finalB;
  });
}

function summaryLimopsDots(crew) {
  let summaryLimopsGroup = [];
  let summaryLimopsDot = [];

  crew.forEach((summaryLimopsItem) => {
    if (summaryLimopsDot.length > 3) {
      summaryLimopsGroup.push(summaryLimopsDot);
      summaryLimopsDot = [];
      summaryLimopsDot.push(summaryLimopsItem);
    } else {
      summaryLimopsDot.push(summaryLimopsItem);
    }
  });

  summaryLimopsGroup.push(summaryLimopsDot);
  return summaryLimopsGroup;
}

function crewStandbyGroupBRList(data) {
  const groupedData = {};
  data.forEach((entry) => {
    const key = `${entry.subFleetCode}${entry.baseCode}`;
    if (!groupedData[key]) {
      groupedData[key] = [];
    }
    groupedData[key].push(entry);
  });
  return Object.values(groupedData);
}

function CrewStandby(props) {
  const [state, setState] = useState({
    currentOp: "",
    crewStandbyListBRA: [],
    crewStandbyListSSC: []
  });
  const [isSsc, setIsSsc] = useState(
    props.crewStandbyConfig
      ? props.crewStandbyConfig.checked === "SSC"
      : props.userCCO === "SSC"
  );
  const [crewStdbyGroupSscData, setCrewStdbyGroupSscData] = useState([]);
  const [crewStdbyGroupBraData, setCrewStdbyGroupBraData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const crewStandByListSSC = useMemo(() => {
    return state.crewStandbyListSSC.reduce((crewList, currentItem, index) => {
      const getDefaultValue = (value) => (value != null ? value : 0);
      crewList.push({
        ...currentItem,
        idx: index,
        t24Effective: getDefaultValue(currentItem.mandoT24),
        t24RemainingFlight: getDefaultValue(currentItem.cabinaT24),
        t24RemainingCabin: getDefaultValue(currentItem.mandoT24),
        pmEffective: getDefaultValue(currentItem.mandoPm),
        pmRemainingFlight: getDefaultValue(currentItem.cabinaPm),
        pmRemainingCabin: getDefaultValue(currentItem.mandoPm),
        amEffective: getDefaultValue(currentItem.mandoAm),
        amRemainingFlight: getDefaultValue(currentItem.cabinaAm),
        amRemainingCabin: getDefaultValue(currentItem.mandoAm),
        crewDistribution: getDefaultValue(currentItem.crewDistribution),
        cabinCrewDistribution: getDefaultValue(
          currentItem.cabinCrewDistribution
        ),
      });
      return crewList;
    }, []);
  }, [state.crewStandbyListSSC]);

  const crewStandByListBRA = useMemo(() => {
    return state.crewStandbyListBRA.reduce((crewList, currentItem, index) => {
      const getDefaultValue = (value) => (value != null ? value : 0);
      crewList.push({
        ...currentItem,
        idx: index,
        t24Effective: getDefaultValue(currentItem.mandoT24),
        t24RemainingFlight: getDefaultValue(currentItem.cabinaT24),
        t24RemainingCabin: getDefaultValue(currentItem.mandoT24),
        pmEffective: getDefaultValue(currentItem.mandoPm),
        pmRemainingFlight: getDefaultValue(currentItem.cabinaPm),
        pmRemainingCabin: getDefaultValue(currentItem.mandoPm),
        amEffective: getDefaultValue(currentItem.mandoAm),
        amRemainingFlight: getDefaultValue(currentItem.cabinaAm),
        amRemainingCabin: getDefaultValue(currentItem.mandoAm),
        crewDistribution: getDefaultValue(currentItem.crewDistribution),
        cabinCrewDistribution: getDefaultValue(
          currentItem.cabinCrewDistribution
        ),
      });
      return crewList;
    }, []);
  }, [state.crewStandbyListBRA]);

  const crewStandByGroupListSSC = useMemo(() => {
    return summaryLimopsDots(crewStandByListSSC);
  }, [crewStandByListSSC]);

  const crewStandByGroupListBRA = useMemo(() => {
    const grouped = crewStandbyGroupBRList(crewStandByListBRA);
    const reordered = reorderBRGroups(grouped);
    return summaryLimopsDots(reordered);
  }, [crewStandByListBRA]);

  const moveSlide = useCallback(
    (slideNum) => {
      if (isSsc) {
        if (slideNum > crewStandByGroupListSSC.length) {
          slideNum = 1;
        }
        actualCfSlide = slideNum;
        setState((prevState) => ({
          ...prevState,
          currentOp:
            crewStandByGroupListSSC.length > 0
              ? crewStandByGroupListSSC[slideNum - 1]
              : "",
        }));
        currentSlideCrew3(slideNum);
      } else {
        if (slideNum > crewStandByGroupListBRA.length) {
          slideNum = 1;
        }
        actualCfSlide = slideNum;
        setState((prevState) => ({
          ...prevState,
          currentOp:
            crewStandByGroupListBRA.length > 0
              ? crewStandByGroupListBRA[slideNum - 1]
              : "",
        }));
        currentSlideCrew3(slideNum);
      }
    },
    [isSsc, crewStandByGroupListSSC, crewStandByGroupListBRA]
  );

  const crewStandByDots = isSsc
    ? crewStandByGroupListSSC
    : crewStandByGroupListBRA;

  useEffect(() => {
    const unsubscribe = getCrewStandbySsc({
      next: (snapshot) => {
        setIsLoading(false);
        const docData = snapshot.data();
        if (docData && docData.data) {
          setCrewStdbyGroupSscData(docData.data);
        }
        setIsLoading(false);
      },
      error: (err) => {
        console.error("Error al suscribirse a crewStdby-groupSsc:", err);
        setIsLoading(false);
      },
    });
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, []);

  useEffect(() => {
    const unsubscribeBra = getCrewStandbyBr({
      next: (snapshotBra) => {
        setIsLoading(false);
        const docDataBra = snapshotBra.data();
        if (docDataBra && docDataBra) {
          setCrewStdbyGroupBraData(docDataBra);
        }
        setIsLoading(false);
      },
      error: (err) => {
        console.error("Error al suscribirse a crewStdby-groupBra:", err);
        setIsLoading(false);
      },
    });
    return () => {
      if (unsubscribeBra) unsubscribeBra();
    };
  }, []);

  useEffect(() => {
    return streamCrewStandby({
      next: (snapshot) => {
        setIsLoading(false);
        const { fltSummaryShift, fltSummaryShiftBra } = snapshot;
        setIsSsc(
          props.crewStandbyConfig
            ? props.crewStandbyConfig.checked === "SSC"
            : props.userCCO === "SSC"
        );
        if (fltSummaryShift && fltSummaryShiftBra) {
          const updatedDataBra = processCrewData(fltSummaryShiftBra.data);
          const updatedDataShift = fltSummaryShift.data.map((item) => ({
            ...item
          }));
          const finalDataSSC = applyFiltersSCL(
            updatedDataShift,
            props?.crewStandbyConfig?.FiltersSSC
          );
          const finalDataBRA = applyFiltersBRA(
            updatedDataBra,
            props?.crewStandbyConfig?.FiltersBR
          );
          setState({
            crewStandbyListBRA: finalDataBRA,
            crewStandbyListSSC: finalDataSSC
          });
          lastUpdate = new Date();
          moveSlide(actualCfSlide);
        } else {
          lastUpdate = new Date();
          setState({
            crewStandbyListBRA: [],
            crewStandbyListSSC: []
          });
        }
      },
      error: (e) => {
        console.error(e);
        setIsLoading(false);
      },
    });
  }, [props, moveSlide]);

  useEffect(() => {
    currentSlideCrew3(actualCfSlide);
    if (!intervalId) {
      intervalId = setInterval(() => moveSlide(actualCfSlide + 1), 25000);
    }
    return () => cleanUpInterval();
  }, [props, moveSlide]);

  const cleanUpInterval = () => {
    clearInterval(intervalId);
    intervalId = null;
  };

  const applyFiltersBRA = (data, filters) => {
    if (!Array.isArray(filters)) {
      return data;
    }
    if (!filters.length) return data;
    return data.filter((item) =>
      filters.includes(item.subFleetCode + item.baseCode)
    );
  };

  const applyFiltersSCL = (data, filters) => {
    if (!Array.isArray(filters)) {
      return data;
    }
    if (!filters.length) return data;

    return data.filter((item) => {
      const companyCode = item.companyCode
        ? item.companyCode.split("/")[0]
        : "";
      return filters.includes(item.subFleetCode + companyCode);
    });
  };

  function processCrewData(data) {
    if (!Array.isArray(data)) {
      throw new Error("Data debe ser un array");
    }

    const timeRanges = [
      { start: "00:00", end: "03:00" },
      { start: "03:00", end: "06:00" },
      { start: "06:00", end: "09:00" },
      { start: "09:00", end: "12:00" },
      { start: "12:00", end: "15:00" },
      { start: "15:00", end: "18:00" },
      { start: "18:00", end: "21:00" },
      { start: "21:00", end: "00:00" },
    ];

    const result = [];

    timeRanges.forEach((range, index) => {
      const filteredData = data.filter(
        (item) => item.workShiftType === index.toString()
      );

      filteredData.forEach((item) => {
        const [assignedMando, neededMando] = item.mando.split("/").map(Number);
        const [assignedCabina, neededCabina] = item.cabina
          .split("/")
          .map(Number);

        const mandoValue = `${isNaN(assignedMando) ? 0 : assignedMando}/${
          isNaN(neededMando) ? 0 : neededMando
        }`;
        const cabinaValue = `${isNaN(assignedCabina) ? 0 : assignedCabina}/${
          isNaN(neededCabina) ? 0 : neededCabina
        }`;

        result.push({
          timeRange: `${range.start}-${range.end}`,
          subFleetCode: item.subFleetCode,
          baseCode: item.baseCode,
          CABINA: cabinaValue,
          MANDO: mandoValue,
        });
      });
    });

    return result;
  }

  return (
    <div className="crew-standby">
      {!isLoading && (
        <>
          <div className="critical-header">
            <GenericTitle
              title={"Crew Standby"}
              color={"#ff8a00"}
              lastUpdate={lastUpdate}
            />
            <div className="cf-counter-grp">
              <div className="cf-counter-grp-b"></div>
            </div>
          </div>

          <div className="crew-standby-main">
            <div style={{ height: "100%", padding: ".5vh" }}>
              {isSsc ? (
                <>
                  <div className="summary-limops-tittle summary-limops-row-style header">
                    <div className="summary-limops-colx2 oh"></div>
                    <div className="summary-limops-colx2">AM</div>
                    <div className="summary-limops-colx2">PM</div>
                    <div className="summary-limops-colx2">ATO</div>
                    <div className="summary-limops-colx2">T24</div>
                  </div>

                  <div className="summary-limops-tittle summary-limops-row-style title">
                    <div className="summary-limops-colx2"></div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">MANDO</div>
                    <div className="summary-limops-sep"></div>
                    <div className="summary-limops-padding">CABINA</div>
                  </div>
                  <div className="crew-slider">
                    {crewStandByGroupListSSC.map((ctalFlightGrp, indx) => (
                      <CrewStandbyGroupSSC
                        key={indx}
                        ctalFlightGrp={ctalFlightGrp}
                        crewStdbyGroupSscData={crewStdbyGroupSscData}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <div className="crew-summary-limops-tittle summary-limops-row-style header brasil-header">
                    <div className="summary-limops-colx2 oh"></div>
                    {[
                      "00:00\n03:00",
                      "03:00\n06:00",
                      "06:00\n09:00",
                      "09:00\n12:00",
                      "12:00\n15:00",
                      "15:00\n18:00",
                      "18:00\n21:00",
                      "21:00\n00:00",
                    ].map((timeRange, index) => (
                      <div
                        className="summary-limops-colx2 brasil-time"
                        key={index}
                      >
                        {timeRange.split("\n").map((time, i) => (
                          <React.Fragment key={i}>
                            {time}
                            {i === 0 && <br />}
                          </React.Fragment>
                        ))}
                      </div>
                    ))}
                  </div>

                  <div className="crew-summary-limops-tittle summary-limops-row-style title bra-brasil-title bg-transparent">
                    <div className="bra-summary-limops-colx2"></div>
                    {Array(8)
                      .fill()
                      .map((_, timeIndex) => (
                        <React.Fragment key={timeIndex}>
                          <div className="bra-summary-limops-sep"></div>
                          {["MANDO", "CABINA"].map((role, roleIndex) => (
                            <React.Fragment key={`${timeIndex}-${roleIndex}`}>
                              <div
                                className={`bra-summary-limops-col ${role.toLowerCase()}`}
                              >
                                {role}
                              </div>
                              {roleIndex === 0 && (
                                <div className="bra-summary-limops-sep"></div>
                              )}
                            </React.Fragment>
                          ))}
                        </React.Fragment>
                      ))}
                  </div>
                  <div className="crew-slider brasil-slider">
                    {crewStandByDots.map((ctalFlightGrp, indx) => (
                      <CrewStandbyGroupBR
                        key={indx}
                        ctalFlightGrp={ctalFlightGrp}
                        indx={indx}
                        crewStdbyGroupBraData={crewStdbyGroupBraData}
                      />
                    ))}
                  </div>

                  <div className="ato-casa">*ATO/CASA</div>
                </>
              )}
            </div>
            <div className="cf-carousell-dots">
              {crewStandByDots.map((_, indx) => (
                <span
                  className="dot3"
                  key={indx}
                  onClick={() => moveSlide(indx + 1)}
                ></span>
              ))}
            </div>
          </div>
        </>
      )}

      {isLoading && (
        <>
          <Loader id="ctrl-loader" />
        </>
      )}
    </div>
  );
}

export default CrewStandby;