import Button from "@material-ui/core/Button";
import React, { useEffect, useState } from 'react';
import Moment from "react-moment";
import Ato from '../../../../vector/ato.png';
import House from '../../../../vector/casa.png';
import { getCrewStandbyBr, updateCrewStandbyBr } from "../../../../firebase";
import GenericSnackbar from "../../../GenericSnackbar/GenericSnackbar";
import "./CrewStandbyMaintainerBrasil.css";

import {
  CREW_STANDBY_BR_AM_GROUP,
  CREW_STANDBY_BR_PM_GROUP,
  CREW_STANDBY_BR,               // ["320SAO","320RIO","320BSB","320POA","787SAO","777SAO"]
  CREW_STANDBY_BR_MANDO_CABINA,  // ["Mando","Cabina","Mando","Cabina","Mando","Cabina","Mando","Cabina"]
  CREW_STANDBY_BRA_MAINTEN_AM,   // Datos AM
  CREW_STANDBY_BRA_MAINTEN_PM    // Datos PM
} from '../../../../utils/Constants';

function CrewStandbyMaintainerBrasil(props) {
  const [snackbar, setSnackbar] = useState('');
  const [crewStandbyAm, setCrewStandbyAm] = useState(CREW_STANDBY_BRA_MAINTEN_AM);
  const [crewStandbyPm, setCrewStandbyPm] = useState(CREW_STANDBY_BRA_MAINTEN_PM);
  const [lastState, setLastState] = useState();


  const onChangeInput = (e, flota, horario, rol, minOrMax, atoOCasa, isAm) => {
    const newValue = e.target.value;

    if (isAm) {
      // Modificar en crewStandbyAm
      const newData = { ...crewStandbyAm };
      if (!newData[flota]) newData[flota] = {};
      if (!newData[flota][horario]) newData[flota][horario] = {};
      if (!newData[flota][horario][rol]) newData[flota][horario][rol] = {};
      if (!newData[flota][horario][rol][minOrMax]) {
        newData[flota][horario][rol][minOrMax] = {};
      }
      newData[flota][horario][rol][minOrMax][atoOCasa] = newValue;
      setCrewStandbyAm(newData);

    } else {
      // Modificar en crewStandbyPm
      const newData = { ...crewStandbyPm };
      if (!newData[flota]) newData[flota] = {};
      if (!newData[flota][horario]) newData[flota][horario] = {};
      if (!newData[flota][horario][rol]) newData[flota][horario][rol] = {};
      if (!newData[flota][horario][rol][minOrMax]) {
        newData[flota][horario][rol][minOrMax] = {};
      }
      newData[flota][horario][rol][minOrMax][atoOCasa] = newValue;
      setCrewStandbyPm(newData);
    }
  };

  useEffect(() => {
    return getCrewStandbyBr({
      next: (querySnapshot) => {
        const data = querySnapshot.data();
        if (data && data.dataAm && data.dataPm) {
          setCrewStandbyAm(data.dataAm);
          setCrewStandbyPm(data.dataPm);
          setLastState(data.lastUpdate);
        } else {
          setCrewStandbyAm(CREW_STANDBY_BRA_MAINTEN_AM);
          setCrewStandbyPm(CREW_STANDBY_BRA_MAINTEN_PM);
        }
      },
      error: (e) => {
        console.error(e);
        setCrewStandbyAm(CREW_STANDBY_BRA_MAINTEN_AM);
        setCrewStandbyPm(CREW_STANDBY_BRA_MAINTEN_PM);
      }
    });
  }, [props]);


  const saveChanges = () => {
    const modDate = new Date();
    const newGoals = {
      dataAm: crewStandbyAm,
      dataPm: crewStandbyPm,
    };

    console.log("Datos a guardar:", newGoals);

    updateCrewStandbyBr({
      dataAm: crewStandbyAm,
      dataPm: crewStandbyPm,
      lastUpdate: modDate.toISOString()
    }).then(
      () => {
        setSnackbar({
          msg: "Succesfully saved changes!",
          date: new Date(),
          severity: "success",
        });
        setLastState(modDate.toISOString());
      },
      (error) => {
        console.error(error);
        setSnackbar({
          msg: "Error saving changes. Try again later",
          date: new Date(),
          severity: "error",
        });
      }
    );
  };

  return (
      <div className='main-br'>
      {/* TABLA AM */}
      <table className='table-ssc-tbl1'>
        <thead>
          <tr className="row-schedule-tbl1">
            <th></th>
              <th className="row-schedule-th-tbl1" colSpan={32}>
              <div className="row-schedule-container-tbl1">
                  <div className="row-schedule-box-tbl1 bra-tbl1-0am">00:00 - 03:00</div>
                  <div className="row-schedule-box-tbl1 bra-tbl1-3am">03:00 - 06:00</div>
                  <div className="row-schedule-box-tbl1 bra-tbl1-6am">06:00 - 09:00</div>
                  <div className="row-schedule-box-tbl1 bra-tbl1-9am">09:00 - 12:00</div>
              </div>
          </th>
          </tr>
          <tr>
            <th></th>
            <div className="row-mando-cabina">
            {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => {
              const turnoIndex = Math.floor(index / 2); 
              const turno = CREW_STANDBY_BR_AM_GROUP[turnoIndex] ? CREW_STANDBY_BR_AM_GROUP[turnoIndex].replace(/[^a-zA-Z0-9]/g, "").toLowerCase() : "";
              const extraClass = title === "Mando" ? `mando-box-tbl1-${turno}` : `cabina-box-tbl1-${turno}`;
              
              return (
                
                  <th className={`theader-br ${extraClass}`} key={`am-hora-header-${index}`}>
                    {title}
                  </th>
              
              );
             
            })}
              </div>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
          {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => {
            const turnoIndex = Math.floor(index / 2);
            const turno = CREW_STANDBY_BR_AM_GROUP[turnoIndex]
              ? CREW_STANDBY_BR_AM_GROUP[turnoIndex].replace(/[^a-zA-Z0-9]/g, "").toLowerCase() : "";

            const rol = title === "Mando" ? "mando" : "cabina";

            return (
              <td className="doble-br" key={`pm-legend-${index}`}>
                {/* Amarillo - Ato */}
                <td className={`amarillo-br a-ato-${rol}-${turno}`} key={`pm-legend-${index}-0`}>
                  <img src={Ato} alt="Ato" className="img-ato-casa" />
                </td>

                {/* Amarillo - Casa */}
                <td className={`amarillo-br borde-br a-casa-${rol}-${turno}`} key={`pm-legend-${index}-1`}>
                  <img src={House} alt="Casa" className="img-ato-casa" />
                </td>

                {/* Rojo - Ato */}
                <td className={`rojo-br borde-br r-ato-${rol}-${turno}`} key={`pm-legend-${index}-2`}>
                  <img src={Ato} alt="Ato" className="img-ato-casa" />
                </td>

                {/* Rojo - Casa */}
                <td className={`rojo-br borde-br r-casa-${rol}-${turno}`} key={`pm-legend-${index}-3`}>
                  <img src={House} alt="Casa" className="img-ato-casa" />
                </td>
              </td>
            );
          })}
          </tr>
          {/* Filas: Por cada flota, por cada horario AM, Mando y Cabina */}
          {CREW_STANDBY_BR.map((flota) => (
            <tr key={`am-row-${flota}`}>
              <th className='col1-br'>{flota}</th>

              {CREW_STANDBY_BR_AM_GROUP.map((hora) => {
                // Obtenemos la data "Mando" del estado crewStandbyAm
                const mandoObj = crewStandbyAm[flota]?.[hora]?.["Mando"] || {};
                const minAtoM  = mandoObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaM = mandoObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoM  = mandoObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaM = mandoObj?.["Max"]?.["Casa"] ?? "";

                // Obtenemos la data "Cabina" del estado crewStandbyAm
                const cabinaObj = crewStandbyAm[flota]?.[hora]?.["Cabina"] || {};
                const minAtoC  = cabinaObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaC = cabinaObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoC  = cabinaObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaC = cabinaObj?.["Max"]?.["Casa"] ?? "";

                return (
                  <td colSpan="2" className='doble-br' key={`am-${flota}-${hora}`}>
                    {/* MANDO MinAto */}
                    <td className='a-ato-mando' key={`am-${flota}-${hora}-mando-minAto`}>
                      <input
                        type="text"
                        className='input-a-ato-mando'
                        value={minAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Ato", true)
                        }
                      />
                    </td>
                    {/* MANDO MinCasa */}
                    <td className='a-casa-mando' key={`am-${flota}-${hora}-mando-minCasa`}>
                      <input
                        type="text"
                        className='input-a-casa-mando'
                        value={minCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Min", "Casa", true)
                        }
                      />
                    </td>
                    {/* MANDO MaxAto */}
                    <td className='r-ato-mando' key={`am-${flota}-${hora}-mando-maxAto`}>
                      <input
                        type="text"
                        className='input-r-ato-mando'
                        value={maxAtoM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Ato", true)
                        }
                      />
                    </td>
                    {/* MANDO MaxCasa */}
                    <td className='r-casa-mando' key={`am-${flota}-${hora}-mando-maxCasa`}>
                      <input
                        type="text"
                        className='input-r-casa-mando'
                        value={maxCasaM}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Mando", "Max", "Casa", true)
                        }
                      />
                    </td>

                    {/* CABINA MinAto */}
                    <td className='a-ato-cabina' key={`am-${flota}-${hora}-cabina-minAto`}>
                      <input
                        type="text"
                        className='input-a-ato-cabina'
                        value={minAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Ato", true)
                        }
                      />
                    </td>
                    {/* CABINA MinCasa */}
                    <td className='a-casa-cabina' key={`am-${flota}-${hora}-cabina-minCasa`}>
                      <input
                        type="text"
                        className='input-a-casa-cabina'
                        value={minCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Min", "Casa", true)
                        }
                      />
                    </td>

                    {/* CABINA MaxAto */}
                    <td className='r-ato-cabina' key={`am-${flota}-${hora}-cabina-maxAto`}>
                      <input
                        type="text"
                        className='input-r-ato-cabina'
                        value={maxAtoC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Ato", true)
                        }
                      />
                    </td>
                    {/* CABINA MaxCasa */}
                    <td className='r-casa-cabina' key={`am-${flota}-${hora}-cabina-maxCasa`}>
                      <input
                        type="text"
                        className='input-r-casa-cabina'
                        value={maxCasaC}
                        onChange={(e) =>
                          onChangeInput(e, flota, hora, "Cabina", "Max", "Casa", true)
                        }
                      />
                    </td>
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      <br />

      {/* TABLA PM */}
      <table className='table-ssc-tbl2'>
      <thead>
          <tr className="row-schedule-tbl2">
            <th></th>
              <th className="row-schedule-th-tbl2" colSpan={32}>
              <div className="row-schedule-container-tbl2">
                  <div className="row-schedule-box-tbl2 bra-tbl2-12pm">12:00 - 15:00</div>
                  <div className="row-schedule-box-tbl2 bra-tbl2-15pm">15:00 - 18:00</div>
                  <div className="row-schedule-box-tbl2 bra-tbl2-18pm">18:00 - 21:00</div>
                  <div className="row-schedule-box-tbl2 bra-tbl2-21pm">21:00 - 00:00</div>
              </div>
          </th>
          </tr>
          <tr>
            <th></th>
            <div className="row-mando-cabina-tbl2">
            {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => {
              const turnoIndex = Math.floor(index / 2); 
              const turno = CREW_STANDBY_BR_AM_GROUP[turnoIndex] ? CREW_STANDBY_BR_AM_GROUP[turnoIndex].replace(/[^a-zA-Z0-9]/g, "").toLowerCase() : "";
              const extraClass = title === "Mando" ? `mando-box-tbl2-${turno}` : `cabina-box-tbl2-${turno}`;
              
              return (
                  <th className={`theader-br ${extraClass}`} key={`pm-hora-header-${index}`}>
                    {title}
                  </th>
              );
             
            })}
              </div>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td></td>
          {CREW_STANDBY_BR_MANDO_CABINA.map((title, index) => {
            const turnoIndex = Math.floor(index / 2);
            const turno = CREW_STANDBY_BR_AM_GROUP[turnoIndex]
              ? CREW_STANDBY_BR_AM_GROUP[turnoIndex].replace(/[^a-zA-Z0-9]/g, "").toLowerCase() : "";

            const rol = title === "Mando" ? "mando" : "cabina";

            return (
              <td className="doble-br-tbl2" key={`pm-legend-${index}`}>
                {/* Amarillo - Ato */}
                <td className={`amarillo-br-tbl2 a-ato-${rol}-${turno}-tbl2`} key={`pm-legend-${index}-0`}>
                  <img src={Ato} alt="Ato" className="img-ato-casa-tbl2" />
                </td>

                {/* Amarillo - Casa */}
                <td className={`amarillo-br-tbl2 a-casa-${rol}-${turno}-tbl2`} key={`pm-legend-${index}-1`}>
                  <img src={House} alt="Casa" className="img-ato-casa-tbl2" />
                </td>

                {/* Rojo - Ato */}
                <td className={`rojo-br-tbl2 r-ato-${rol}-${turno}-tbl2`} key={`pm-legend-${index}-2`}>
                  <img src={Ato} alt="Ato" className="img-ato-casa-tbl2" />
                </td>

                {/* Rojo - Casa */}
                <td className={`rojo-br-tbl2 r-casa-${rol}-${turno}-tbl2`} key={`pm-legend-${index}-3`}>
                  <img src={House} alt="Casa" className="img-ato-casa-tbl2" />
                </td>
              </td>
            );
          })}
          </tr>

          {/* Filas con los inputs PM (Mando & Cabina) por flota */}
          {CREW_STANDBY_BR.map((flota) => (
            <tr key={`pm-row-${flota}`}>
              <th className='col1-br-tbl2'>{flota}</th>

              {CREW_STANDBY_BR_PM_GROUP.map((hora) => {
                // Data Mando en PM
                const mandoObj = crewStandbyPm[flota]?.[hora]?.["Mando"] || {};
                const minAtoM  = mandoObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaM = mandoObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoM  = mandoObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaM = mandoObj?.["Max"]?.["Casa"] ?? "";

                // Data Cabina en PM
                const cabinaObj = crewStandbyPm[flota]?.[hora]?.["Cabina"] || {};
                const minAtoC  = cabinaObj?.["Min"]?.["Ato"]  ?? "";
                const minCasaC = cabinaObj?.["Min"]?.["Casa"] ?? "";
                const maxAtoC  = cabinaObj?.["Max"]?.["Ato"]  ?? "";
                const maxCasaC = cabinaObj?.["Max"]?.["Casa"] ?? "";

                return (
                  <td colSpan="2" className='doble-br-tbl2' key={`pm-${flota}-${hora}`}>
                  {/* MANDO MinAto */}
                  <td className='a-ato-mando-tbl2' key={`pm-${flota}-${hora}-mando-minAto`}>
                    <input
                      type="text"
                      className='input-a-ato-mando-tbl2'
                      value={minAtoM}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Mando", "Min", "Ato", false)
                      }
                    />
                  </td>
                  {/* MANDO MinCasa */}
                  <td className='a-casa-mando-tbl2' key={`pm-${flota}-${hora}-mando-minCasa`}>
                    <input
                      type="text"
                      className='input-a-casa-mando-tbl2'
                      value={minCasaM}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Mando", "Min", "Casa", false)
                      }
                    />
                  </td>
                  {/* MANDO MaxAto */}
                  <td className='r-ato-mando-tbl2' key={`pm-${flota}-${hora}-mando-maxAto`}>
                    <input
                      type="text"
                      className='input-r-ato-mando-tbl2'
                      value={maxAtoM}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Mando", "Max", "Ato", false)
                      }
                    />
                  </td>
                  {/* MANDO MaxCasa */}
                  <td className='r-casa-mando-tbl2' key={`pm-${flota}-${hora}-mando-maxCasa`}>
                    <input
                      type="text"
                      className='input-r-casa-mando-tbl2'
                      value={maxCasaM}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Mando", "Max", "Casa", false)
                      }
                    />
                  </td>

                  {/* CABINA MinAto */}
                  <td className='a-ato-cabina-tbl2' key={`pm-${flota}-${hora}-cabina-minAto`}>
                    <input
                      type="text"
                      className='input-a-ato-cabina-tbl2'
                      value={minAtoC}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Cabina", "Min", "Ato", false)
                      }
                    />
                  </td>
                  {/* CABINA MinCasa */}
                  <td className='a-casa-cabina-tbl2' key={`pm-${flota}-${hora}-cabina-minCasa`}>
                    <input
                      type="text"
                      className='input-a-casa-cabina-tbl2'
                      value={minCasaC}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Cabina", "Min", "Casa", false)
                      }
                    />
                  </td>

                  {/* CABINA MaxAto */}
                  <td className='r-ato-cabina-tbl2' key={`pm-${flota}-${hora}-cabina-maxAto`}>
                    <input
                      type="text"
                      className='input-r-ato-cabina-tbl2'
                      value={maxAtoC}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Cabina", "Max", "Ato", false)
                      }
                    />
                  </td>
                  {/* CABINA MaxCasa */}
                  <td className='r-casa-cabina-tbl2' key={`pm-${flota}-${hora}-cabina-maxCasa`}>
                    <input
                      type="text"
                      className='input-r-casa-cabina-tbl2'
                      value={maxCasaC}
                      onChange={(e) =>
                        onChangeInput(e, flota, hora, "Cabina", "Max", "Casa", false)
                      }
                    />
                  </td>
                </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>

      <div className="cf-buttons-bra">
        <div className="last-mod">
        <span className="bold-text-bra">
          Last modification made:{" "}
          {lastState ? (
            <Moment format="DD/MM/yyyy HH:mm" tz="UTC">
              {lastState}
            </Moment>
          ) : null}</span>
        </div>
        <Button
          variant="contained"
          classes={{ root: 'vw-button' }}
          onClick={saveChanges}
          disableElevation
        >
          Apply changes to video wall
        </Button>
      </div>

      {snackbar ? (
        <GenericSnackbar
          key={snackbar.date}
          severity={snackbar.severity}
          message={snackbar.msg}
        />
      ) : null}
    </div>
  );
}

export default CrewStandbyMaintainerBrasil;
